module.exports = {
  storageKeys: {
    IPECS_LOGIN: "ipecs_login",
    DEVICES: "devices",
    HEADSET: "headset",
  },
  channels: {
    SET_AUTH: "set-auth",
    FETCH_AUTH: "fetch-auth",
    DELETE_AUTH: "delete-auth",
    SET_DEVICE: "set-device",
    GET_DEVICE: "get-device",
    REQUEST_MEDIA_ACCESS: "request-media-access",
    GET_INTEGRATIONS: "get-integrations",
    MS: {
      LOGIN: "ms-login",
      LOGOUT: "ms-logout",
      GET_CALENDAR_EVENTS: "ms-calendar-events",
    },
  },
};
