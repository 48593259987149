module.exports = {
  app_url: process.env.REACT_APP_APP_URL ?? "",
  api_url: process.env.REACT_APP_API_URL
    ? process.env.REACT_APP_API_URL
    : "http://localhost",
  api_version: 5,
  customer: process.env.REACT_APP_CUSTOMER,
  logrocket: process.env.REACT_APP_LOGROCKET,
  privacy_policy_url: "https://cosoft.co.uk/connect-privacy-policy/",
  tenor_api_key: process.env.REACT_APP_TENOR,
  click_to_dial_extension_id:
    process.env.REACT_APP_CTD_EXTENSION_ID ??
    "joolkfmijoplolnckgdicbnijjjmhdlm",
};
