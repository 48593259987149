import { sdk } from "@gc/ipecs-web-sdk";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { toast } from "react-toastify";
import call1Animation from "../animations/call1.json";
import { postCallAnswered, postCallEnded, postCallStarted } from "../api/calls";
import { lookupByCallerid } from "../api/contacts";
import ActiveSoftphoneCall from "../components/softphone/ActiveSoftphoneCall";
import IncomingSoftphoneCallAlert from "../components/softphone/IncomingSoftphoneCallAlert";
import { closePopupDialler, focusIncomingCall } from "../helpers/clickToDial";
import { convertToE164 } from "../helpers/convertToE164";
import { useAuth } from "./AuthContext";
import { useCallLogs } from "./CallLogsContext";
import { useDevices } from "./DevicesContext";

const callAnimations = [call1Animation];

const SoftphoneContext = React.createContext();
const SoftphoneProvider = ({ children }) => {
  const { call } = sdk;
  const { refetchCallLogs, refetchMissedCallCount } = useCallLogs();
  const { headsetDevice, headsetReportId, setHeadsetReportId } = useDevices();

  const [minimized, setMinimized] = useState(false);
  const [connecting, setConnecting] = useState(false);
  const [ringing, setRinging] = useState(false);
  const [disconnecting, setDisconnecting] = useState(false);
  const [numberInvalid, setNumberInvalid] = useState(false);
  const [activeCall, setActiveCall] = useState();
  const [consultationCall, setConsultationCall] = useState();
  const [activeCallPartyInfo, setActiveCallPartyInfo] = useState();
  const [incomingCallPartyInfo, setIncomingCallPartyInfo] = useState();
  const [incomingCall, setIncomingCall] = useState();
  const [blindTransferring, setBlindTransferring] = useState(false);
  const [transferNumber, setTransferNumber] = useState("");
  const [onHold, setOnHold] = useState(false);
  const [muted, setMuted] = useState(false);
  const [videoMuted, setVideoMuted] = useState(false);
  const [recordingStatus, setRecordingStatus] = useState({
    isRecording: false,
  });
  const [callDirection, setCallDirection] = useState();
  const [callDuration, setCallDuration] = useState();

  const [eventCallRejected, setEventCallRejected] = useState();
  const [eventOutgoingInvalid, setEventOutgoingInvalid] = useState();
  const [eventCallEnded, setEventCallEnded] = useState();
  const [eventOutgoingAnswered, setEventOutgoingAnswered] = useState();
  const [
    eventOutgoingCallEarlyMediaConnected,
    setEventOutgoingCallEarlyMediaConnected,
  ] = useState();
  const [eventIncomingCallReceived, setEventIncomingCallReceived] = useState();
  const [eventIncomingCallCancelled, setEventIncomingCallCancelled] =
    useState();
  const [eventTransferToReceived, setEventTransferToReceived] = useState();
  const [eventTransferredReceived, setEventTransferredReceived] = useState();
  const [eventRecordingStatusChanged, setEventRecordingStatusChanged] =
    useState();
  const [eventOnUnParkedToReceived, setEventOnUnParkedToReceived] = useState();

  const callAnimationRef = useRef();
  const localVideoRef = useRef();
  const remoteVideoRef = useRef();
  const remoteAudioRef = useRef();

  const { apiUser } = useAuth();

  const handleCloseOverlay = useCallback(
    (ticketToClose) => {
      if (activeCall) {
        const { ticket, localMedia } = activeCall;
        if (ticketToClose === ticket && localMedia && localMedia.stop) {
          localMedia.stop();
        }
      }

      setActiveCall();
      setConsultationCall();
      setTransferNumber("");
      setOnHold(false);
      setMuted(false);
      setVideoMuted(false);
      setRinging(false);
      setConnecting(false);
      setBlindTransferring(false);
      setNumberInvalid(false);
      refetchCallLogs();
      closePopupDialler();
    },
    [activeCall, refetchCallLogs],
  );

  // prevent refresh if call is ongoing
  useEffect(() => {
    window.onbeforeunload = function () {
      if (activeCall) return false;
      // else do nothing
    };
  }, [activeCall]);

  // unset incoming call party info if no incoming call
  useEffect(() => {
    if (!incomingCall) {
      setIncomingCallPartyInfo();
    }
  }, [incomingCall, setIncomingCallPartyInfo]);

  // this will stop ringing if an inbound call is answered by another device
  useEffect(() => {
    let incomingCallInterval = undefined;

    if (incomingCall) {
      incomingCallInterval = setInterval(() => {
        call
          .startKeepAliveCall(incomingCall.ticket)
          .catch(() => setIncomingCall());
      }, 2500);
    } else {
      if (incomingCallInterval !== undefined) {
        clearInterval(incomingCallInterval);
      }
    }

    return () => {
      if (incomingCallInterval !== undefined) {
        clearInterval(incomingCallInterval);
      }
    };
  }, [call, incomingCall]);

  // setup event listeners
  useEffect(() => {
    call.onOutgoingCallRejected(({ ticket }) => {
      setEventCallRejected(ticket);
    });

    call.onOutgoingCallInvalidNumber(({ ticket }) => {
      setEventOutgoingInvalid(ticket);
    });

    call.onCallEnded(({ ticket }) => {
      setEventCallEnded(ticket);
    });

    call.onOutgoingCallAnswered(({ ticket }) => {
      setEventOutgoingAnswered(ticket);
    });

    call.onOutgoingCallEarlyMediaConnected((event) => {
      setEventOutgoingCallEarlyMediaConnected(event);
    });

    call.onIncomingCallReceived((event) => {
      setEventIncomingCallReceived(event);
    });

    call.onRecordingStatusChanged((event) => {
      setEventRecordingStatusChanged(event);
    });

    call.onIncomingCallCancelled(({ ticket }) => {
      setEventIncomingCallCancelled(ticket);
    });

    call.onTransferToReceived((event) => {
      setEventTransferToReceived(event);
    });

    call.onTransferredReceived((event) => {
      setEventTransferredReceived(event);
    });

    call.onUnParkedToReceived((event) => {
      setEventOnUnParkedToReceived(event);
    });
  }, [call]);

  const handleCallAnswered = useCallback(async () => {
    if (callDirection && activeCall?.callNumber) {
      try {
        if (
          callDirection === "OUTBOUND" &&
          apiUser?.record_calls === "INBOUND"
        ) {
          call.stopRecording();
        }
      } catch {
        // do nothing
      }

      try {
        await postCallAnswered({
          direction: callDirection,
          number: activeCall.callNumber,
        });
      } catch {
        // do nothing
      }
    }
  }, [callDirection, activeCall, call, apiUser.record_calls]);

  const handleCallEnded = useCallback(async () => {
    try {
      await postCallEnded({
        provider_id: activeCall.ticket,
        duration: callDuration,
        direction: callDirection,
        number: activeCall.callNumber,
      });
    } catch {
      // do nothing
    } finally {
      setCallDirection();
      setCallDuration();
      setNumberInvalid(false);
    }
  }, [callDirection, callDuration, activeCall]);

  // handle rejected calls
  useEffect(() => {
    if (eventCallRejected && activeCall) {
      if (eventCallRejected === activeCall.ticket) {
        handleCloseOverlay(activeCall.ticket);
        setEventCallRejected();
        toast("Call rejected", { toastId: eventCallRejected, type: "error" });
      }
    }

    if (eventCallRejected && consultationCall) {
      if (eventCallRejected === consultationCall.ticket) {
        toast(
          "Existing call in on hold, try another number of cancel transfer to retrieve.",
          {
            toastId: `${eventCallRejected}-info`,
            type: "info",
          },
        );

        toast("Transfer call rejected by user", {
          toastId: eventCallRejected,
          type: "error",
        });

        if (consultationCall.localMedia && consultationCall.localMedia.stop)
          consultationCall.localMedia.stop();
        setConsultationCall();
        setTransferNumber("");

        activeCall.remoteMedia &&
          activeCall.remoteMedia.connect &&
          activeCall.remoteMedia.connect(remoteAudioRef.current);
      }
    }
  }, [eventCallRejected, activeCall, consultationCall, handleCloseOverlay]);

  // handle outgoing invalid
  useEffect(() => {
    if (eventOutgoingInvalid && activeCall) {
      const { ticket } = activeCall;
      if (eventOutgoingInvalid === ticket) {
        setEventOutgoingInvalid();
        setNumberInvalid(true);
        console.error("Invalid number event");
        toast("Invalid number", {
          toastId: eventOutgoingInvalid,
          type: "error",
        });
      }
    }

    if (eventOutgoingInvalid && consultationCall) {
      if (eventOutgoingInvalid === consultationCall.ticket) {
        toast(
          "Existing call in on hold, try another number of cancel transfer to retrieve.",
          {
            toastId: `${eventOutgoingInvalid}-info`,
            type: "info",
          },
        );

        toast("Invalid transfer number", {
          toastId: eventOutgoingInvalid,
          type: "error",
        });

        if (consultationCall.localMedia && consultationCall.localMedia.stop)
          consultationCall.localMedia.stop();
        setConsultationCall();
        setTransferNumber("");

        activeCall.remoteMedia &&
          activeCall.remoteMedia.connect &&
          activeCall.remoteMedia.connect(remoteAudioRef.current);
      }
    }
  }, [eventOutgoingInvalid, activeCall, consultationCall, handleCloseOverlay]);

  // handle call ended
  useEffect(() => {
    if (eventCallEnded && activeCall) {
      const { ticket } = activeCall;
      if (eventCallEnded === ticket) {
        handleCloseOverlay(ticket);
        setEventCallEnded();
        handleCallEnded();
      }
    }

    if (eventCallEnded && consultationCall) {
      if (eventCallEnded === consultationCall.ticket) {
        toast(
          "Existing call in on hold, try another number of cancel transfer to retrieve.",
          {
            toastId: `${eventCallEnded}-info`,
            type: "info",
          },
        );

        toast("Transfer call ended by user", {
          toastId: eventCallEnded,
          type: "error",
        });

        if (consultationCall.localMedia && consultationCall.localMedia.stop)
          consultationCall.localMedia.stop();
        setConsultationCall();
        setTransferNumber("");

        activeCall.remoteMedia &&
          activeCall.remoteMedia.connect &&
          activeCall.remoteMedia.connect(remoteAudioRef.current);
      }
    }
  }, [
    eventCallEnded,
    activeCall,
    consultationCall,
    handleCloseOverlay,
    handleCallEnded,
  ]);

  // handle outgoing answered
  useEffect(() => {
    const handle = async () => {
      if (eventOutgoingAnswered) {
        if (activeCall && eventOutgoingAnswered === activeCall.ticket) {
          const { ticket, mediaType, localMedia, remoteMedia } = activeCall;
          if (eventOutgoingAnswered === ticket) {
            setActiveCall({ ...activeCall, state: "answered" });

            if (mediaType === "video") {
              remoteMedia &&
                remoteMedia.connect &&
                remoteMedia.connect(remoteVideoRef.current);

              localMedia &&
                localMedia.connect &&
                localVideoRef.current &&
                localMedia.connect(localVideoRef.current);
            }

            remoteMedia &&
              remoteMedia.connect &&
              remoteMedia.connect(remoteAudioRef.current);

            try {
              await call.startKeepAliveCall(eventOutgoingAnswered);
            } catch (error) {
              console.log("startKeepAliveCall error:", error);
            }

            setRinging(false);
            setConnecting(false);
          }
        }

        if (
          consultationCall &&
          eventOutgoingAnswered === consultationCall.ticket
        ) {
          setRinging(false);
        }
      }
    };

    handle();
    setEventOutgoingAnswered();
  }, [eventOutgoingAnswered, activeCall, consultationCall, call]);

  // handle outgoing call early media connected
  // this is used to get ringing back, busy tones etc
  useEffect(() => {
    if (eventOutgoingCallEarlyMediaConnected && activeCall) {
      if (eventOutgoingCallEarlyMediaConnected.ticket === activeCall.ticket) {
        const { mediaType, remoteMedia, localMedia } = activeCall;

        setRinging(false);

        if (mediaType === "video") {
          remoteMedia &&
            remoteMedia.connect &&
            remoteMedia.connect(remoteVideoRef.current);

          localMedia &&
            localMedia.connect &&
            localVideoRef.current &&
            localMedia.connect(localVideoRef.current);
        }

        remoteMedia &&
          remoteMedia.connect &&
          remoteMedia.connect(remoteAudioRef.current);

        setEventOutgoingCallEarlyMediaConnected();
      }
    }
  }, [eventOutgoingCallEarlyMediaConnected, activeCall]);

  // handle incoming call received
  useEffect(() => {
    const handle = async () => {
      if (!activeCall && eventIncomingCallReceived) {
        await call.sendAckOfferCall(eventIncomingCallReceived.ticket);
        setCallDirection("INBOUND");
        setIncomingCall(eventIncomingCallReceived);

        const { ticket, callNumber, mediaType } = eventIncomingCallReceived;

        if (incomingCall?.ticket !== ticket) {
          call
            .getOtherPartyInfo({
              ticket: ticket,
              peerId: eventIncomingCallReceived.peerId,
            })
            .then((res) => {
              const notification = new Notification(
                `Incoming ${mediaType} call`,
                {
                  body: `From ${res.name}`,
                  icon: "https://connect.cosoft.co.uk/favicon-32x32.png",
                },
              );
              notification.onclick = () => window.focus();

              setIncomingCallPartyInfo(res);
            })
            .catch((e) => {
              const e164Number = convertToE164(
                callNumber,
                apiUser?.phone_locality,
              );

              lookupByCallerid(e164Number, 2000)
                .then((data) => {
                  const contact = data?.data?.data;
                  const contactName =
                    contact?.first_name || contact?.last_name
                      ? [contact?.first_name, contact?.last_name].join(" ")
                      : callNumber;

                  const notification = new Notification(
                    `Incoming ${mediaType} call`,
                    {
                      body: `From ${contactName}`,
                      icon: "https://connect.cosoft.co.uk/favicon-32x32.png",
                    },
                  );
                  notification.onclick = () => window.focus();
                })
                .catch(() => {
                  const notification = new Notification(
                    `Incoming ${mediaType} call`,
                    {
                      body: `From ${callNumber}`,
                      icon: "https://connect.cosoft.co.uk/favicon-32x32.png",
                    },
                  );
                  notification.onclick = () => window.focus();
                });
            });

          // Send a message to the Click to Dial extension to optionally focus an open Connect tab
          focusIncomingCall();
        }

        setEventIncomingCallReceived();
      }
    };

    handle();
  }, [call, eventIncomingCallReceived, activeCall, incomingCall, apiUser]);

  // handle incoming call cancelled
  useEffect(() => {
    if (eventIncomingCallCancelled && incomingCall) {
      const { ticket } = incomingCall;
      if (eventIncomingCallCancelled === ticket) {
        setIncomingCall();
        refetchMissedCallCount();
        refetchCallLogs();
        setEventIncomingCallCancelled();
      }
    }
  }, [
    eventIncomingCallCancelled,
    incomingCall,
    refetchMissedCallCount,
    refetchCallLogs,
  ]);

  // handle transfer to received
  useEffect(() => {
    if (eventTransferToReceived && activeCall) {
      if (eventTransferToReceived?.ticket === activeCall?.ticket) {
        const newActiveCall = {
          ...activeCall,
          peerId: eventTransferToReceived.newPeerId,
          roomId: eventTransferToReceived.newRoomId,
          callNumber: eventTransferToReceived.newRemoteNumber,
        };

        setActiveCall(newActiveCall);
        setActiveCallPartyInfo({
          avatar: undefined,
          name: eventTransferToReceived.newRemoteNumber,
          callNumber: eventTransferToReceived.newRemoteNumber,
        });

        setEventTransferToReceived();
      }
    }
  }, [eventTransferToReceived, activeCall]);

  // handle transferred received
  useEffect(() => {
    if (eventTransferredReceived && activeCall) {
      if (eventTransferredReceived?.ticket === activeCall?.ticket) {
        const newActiveCall = {
          ...activeCall,
          peerId: eventTransferredReceived.newPeerId,
          roomId: eventTransferredReceived.newRoomId,
          callNumber: eventTransferredReceived.newRemoteNumber,
        };

        setActiveCall(newActiveCall);
        setEventTransferredReceived();
      }
    }
  }, [eventTransferredReceived, activeCall]);

  // handle recording status changed
  useEffect(() => {
    if (activeCall && eventRecordingStatusChanged) {
      setRecordingStatus(eventRecordingStatusChanged);
    }
  }, [eventRecordingStatusChanged, activeCall]);

  // handle unparked call event
  useEffect(() => {
    if (eventOnUnParkedToReceived && activeCall) {
      if (eventOnUnParkedToReceived?.ticket === activeCall?.ticket) {
        const newActiveCall = {
          ...activeCall,
          peerId: eventOnUnParkedToReceived.newPeerId,
          roomId: eventOnUnParkedToReceived.newRoomId,
          callNumber: eventOnUnParkedToReceived.newRemoteNumber,
        };

        setActiveCall(newActiveCall);
        setEventOnUnParkedToReceived();

        try {
          postCallStarted({
            direction: "OUTBOUND",
            number: eventOnUnParkedToReceived.newRemoteNumber,
          });
        } catch {
          // do nothing
        }
      }
    }
  }, [eventOnUnParkedToReceived, activeCall]);

  // handle headset integration - send report
  useEffect(() => {
    if (headsetDevice && headsetDevice.opened) {
      try {
        if (activeCall) {
          headsetDevice.sendReport(0x02, new Uint8Array([1]));
        } else if (incomingCall) {
          headsetDevice.sendReport(0x02, new Uint8Array([4]));
        } else {
          headsetDevice.sendReport(0x02, new Uint8Array([0x00]));
        }
      } catch {
        // do nothing
      }
    }
  }, [headsetDevice, activeCall, incomingCall]);

  const handleMakeCall = (destinationNumber, mediaType, delay = 0) => {
    if (activeCall) {
      toast("You're already on a call", { type: "error" });
      return;
    }

    startCall(destinationNumber, mediaType, delay);
  };

  const startCall = useCallback(
    async (destinationNumber, mediaType, delay = 0) => {
      setCallDirection("OUTBOUND");

      try {
        setConnecting(true);
        setMinimized(false);

        // optionally delay sending the request to ipecs
        if (delay > 0) {
          await new Promise((r) => setTimeout(r, delay));
        }

        const callInfo = await call.makeCall({
          destinationNumber,
          mediaType: mediaType,
        });
        setActiveCall(callInfo);
        setRinging(true);

        callInfo.remoteMedia &&
          callInfo.remoteMedia.connect &&
          callInfo.remoteMedia.connect(remoteAudioRef.current);

        callInfo.localMedia &&
          callInfo.localMedia.connect &&
          localVideoRef.current &&
          callInfo.localMedia.connect(localVideoRef.current);

        // set a random animation
        callAnimationRef.current =
          callAnimations[Math.floor(Math.random() * callAnimations.length)];

        try {
          postCallStarted({
            direction: "OUTBOUND",
            number: destinationNumber,
          });
        } catch {
          // do nothing
        }

        try {
          setActiveCallPartyInfo(
            await call.getOtherPartyInfo({
              ticket: callInfo.ticket,
              peerId: callInfo.peerId,
            }),
          );
        } catch (e) {
          setActiveCallPartyInfo();
        }
      } catch (e) {
        switch (e.message) {
          case "E_CALL_MEDIA_ACCESS_ERROR":
            toast("Failed to connect to your media device", { type: "error" });
            break;
          case "E_CALL_PARAM_ERROR":
            toast(`Invalid number | ${destinationNumber}`, { type: "error" });
            break;
          default:
            console.error(e);
            toast(`Failed to call | ${destinationNumber}`, { type: "error" });
        }

        setRinging(false);
        setConnecting(false);
      }
    },
    [call],
  );

  const handleHangup = useCallback(async () => {
    setDisconnecting(true);

    if (activeCall) {
      try {
        await call.hangupCall(activeCall.ticket);
        await handleCallEnded();
      } catch (e) {
        // do nothing
      }
    }

    setDisconnecting(false);
    handleCloseOverlay(activeCall?.ticket);
  }, [activeCall, call, handleCallEnded, handleCloseOverlay]);

  const handleSendDtmf = async (DTMF, ticket) => {
    try {
      await call.sendDTMFReq({ ticket, DTMF: DTMF.toString() });
    } catch (e) {
      console.log(e);
      toast("Failed to send DTMF", { type: "error" });
    }
  };

  const handleBlindTransfer = async (transferNumber) => {
    if (activeCall) {
      const { ticket } = activeCall;

      try {
        setBlindTransferring(true);
        await call.blindTransferCall({ ticket, transferNumber });
        handleCloseOverlay(ticket);
      } catch (e) {
        setBlindTransferring(false);
        toast("Failed to transfer call", { type: "error" });
      }
    }
  };

  const handleTransfer = async (transferNumber) => {
    if (activeCall) {
      const { ticket } = activeCall;

      try {
        setTransferNumber(transferNumber);
        setOnHold(true);
        const res = await call.consultationCallReq({
          consultationType: "transfer",
          ticket: ticket,
          number: transferNumber,
        });

        res.remoteMedia &&
          res.remoteMedia.connect &&
          res.remoteMedia.connect(remoteAudioRef.current);

        setConsultationCall(res);
        setRinging(true);
      } catch (e) {
        console.log(e);
        setConsultationCall();
        setTransferNumber("");
        setOnHold(false);
        setRinging(false);
        toast("Failed to transfer call", { type: "error" });
      }
    }
  };

  const handleTransferComplete = async () => {
    if (activeCall) {
      try {
        await call.transferCompleteReq(consultationCall.ticket);
        setConsultationCall();
        handleCloseOverlay(activeCall.ticket);
      } catch (e) {
        toast("Failed to transfer call", { type: "error" });
      }
    }
  };

  const handleTransferCancel = async () => {
    if (activeCall) {
      try {
        const { ticket, parentTicket, localMedia } = consultationCall;
        await call.transferRetrieveReq({
          ticket,
          parentTicket,
        });
        if (localMedia && localMedia.stop) localMedia.stop();
        setConsultationCall();
        setTransferNumber("");
        setOnHold(false);
        setRinging(false);

        activeCall.remoteMedia &&
          activeCall.remoteMedia.connect &&
          activeCall.remoteMedia.connect(remoteAudioRef.current);
      } catch (e) {
        toast("Failed to cancel transfer", { type: "error" });
      }
    }
  };

  const handleParkCall = async (parkingLocation) => {
    if (activeCall) {
      const { ticket } = activeCall;

      try {
        await call.parkingCall({ parkingLocation: parkingLocation, ticket });
        handleCallEnded();
        handleCloseOverlay(ticket);
        toast(`Call parked to location ${parkingLocation}`, {
          type: "success",
        });
      } catch (e) {
        console.log(e);
        toast("Failed to park call", { type: "error" });
      }
    }
  };

  const handleUnparkCall = async (parkingLocation) => {
    if (!activeCall) {
      try {
        setConnecting(true);
        setMinimized(false);
        const callInfo = await call.unParkingCall({ parkingLocation });
        setActiveCall(callInfo);
        setConnecting(false);

        callInfo.localMedia &&
          callInfo.localMedia.connect &&
          localVideoRef.current &&
          callInfo.localMedia.connect(localVideoRef.current);

        // set a random animation
        callAnimationRef.current =
          callAnimations[Math.floor(Math.random() * callAnimations.length)];

        try {
          setActiveCallPartyInfo(
            await call.getOtherPartyInfo({
              ticket: callInfo.ticket,
              peerId: callInfo.peerId,
            }),
          );
        } catch (e) {
          setActiveCallPartyInfo();
        }
      } catch (e) {
        console.log(e);
        toast("Failed to unpark call", { type: "error" });
      }
    }
  };

  const handleAnswerIncoming = useCallback(
    async (answerMediaType) => {
      if (incomingCall) {
        try {
          const { ticket, peerId } = incomingCall;
          setActiveCall(incomingCall);

          const { localMedia, remoteMedia } = await call.answerCall({
            ticket,
            mediaType: answerMediaType,
          });

          setActiveCall({
            ...incomingCall,
            localMedia,
            remoteMedia,
            state: "answered",
          });

          try {
            if (answerMediaType === "video") {
              remoteMedia &&
                remoteMedia.connect &&
                remoteMedia.connect(remoteVideoRef.current);

              localMedia &&
                localMedia.connect &&
                localVideoRef.current &&
                localMedia.connect(localVideoRef.current);
            }

            remoteMedia &&
              remoteMedia.connect &&
              remoteMedia.connect(remoteAudioRef.current);

            // set a random animation
            callAnimationRef.current =
              callAnimations[Math.floor(Math.random() * callAnimations.length)];

            setIncomingCall();

            await call.startKeepAliveCall(ticket);
          } catch (error) {
            console.log("startKeepAliveCall error:", error);
          }

          try {
            setActiveCallPartyInfo(
              await call.getOtherPartyInfo({
                ticket: ticket,
                peerId: peerId,
              }),
            );
          } catch (e) {
            setActiveCallPartyInfo();
          }
        } catch (e) {
          setActiveCall();
          toast("Failed to answer call", { type: "error" });
          throw e;
        }
      }
    },
    [call, incomingCall],
  );

  const hndleVmTransferIncoming = async () => {
    if (incomingCall) {
      try {
        await call.vmTransfer({ ticket: incomingCall.ticket });
        setIncomingCall();
      } catch (e) {
        console.log(e);
        toast("Failed to send call to voicemail", { type: "error" });
      }
    }
  };

  const handleRejectIncoming = useCallback(async () => {
    if (incomingCall) {
      try {
        await call.rejectCall(incomingCall.ticket);
        setIncomingCall();
      } catch (e) {
        // even if the request fails, remove incoming call so the incoming call popup doesn't persist
        setIncomingCall();
        throw e;
      }
    }
  }, [call, incomingCall]);

  const handleToggleOnHold = async () => {
    if (activeCall) {
      const { ticket } = activeCall;

      try {
        onHold
          ? await call.retrieveCall(activeCall.ticket)
          : await call.holdCall(activeCall);
        setOnHold(!onHold);
      } catch (e) {
        console.log(e);
        toast("Failed to toggle on hold", { toastId: ticket, type: "error" });
      }
    }
  };

  const handleToggleMuted = async () => {
    if (activeCall) {
      const { ticket, localMedia } = activeCall;

      try {
        muted
          ? await call.unMuteReq({ localMedia, ticket })
          : await call.muteReq({ localMedia, ticket });
        setMuted(!muted);
      } catch (e) {
        toast("Failed to toggle mute", { toastId: ticket, type: "error" });
      }
    }
  };

  const handleToggleRecording = async () => {
    if (activeCall) {
      try {
        recordingStatus.isRecording
          ? await call.stopRecording()
          : await call.startRecording();
      } catch (e) {
        toast("Failed to toggle recording", {
          type: "error",
        });
      }
    }
  };

  const handleToggleVideoMuted = async () => {
    if (activeCall) {
      const { ticket, localMedia } = activeCall;

      try {
        muted
          ? await call.videoUnMuteReq({ localMedia, ticket })
          : await call.videoMuteReq({ localMedia, ticket });
        setVideoMuted(!videoMuted);
      } catch (e) {
        toast("Failed to toggle mute", { toastId: ticket, type: "error" });
      }
    }
  };

  // handle headset integration - receive report
  useEffect(() => {
    if (headsetDevice && headsetDevice.opened && headsetReportId) {
      try {
        if (incomingCall && !activeCall) {
          if (headsetReportId === "1-2") {
            handleAnswerIncoming();
          } else if (headsetReportId === "64-2") {
            handleRejectIncoming();
          }
        } else if (
          activeCall &&
          activeCall?.state === "answered" &&
          headsetReportId === "1-2"
        ) {
          handleHangup();
        }
      } catch {
        // do nothing
      }

      setHeadsetReportId();
    }
  }, [
    headsetDevice,
    activeCall,
    incomingCall,
    headsetReportId,
    setHeadsetReportId,
    handleAnswerIncoming,
    handleRejectIncoming,
    handleHangup,
  ]);

  const defaultContext = {
    setConnecting,
    activeCall,
    consultationCall,
    handleMakeCall,
    minimized,
    setMinimized,
    callAnimationRef,
    remoteAudioRef,
    remoteVideoRef,
    localVideoRef,
    connecting,
    disconnecting,
    numberInvalid,
    ringing,
    activeCallPartyInfo,
    incomingCallPartyInfo,
    handleHangup,
    handleSendDtmf,
    blindTransferring,
    handleBlindTransfer,
    transferNumber,
    handleTransfer,
    handleTransferComplete,
    handleTransferCancel,
    handleParkCall,
    handleUnparkCall,
    incomingCall,
    handleAnswerIncoming,
    hndleVmTransferIncoming,
    handleRejectIncoming,
    onHold,
    handleToggleOnHold,
    muted,
    handleToggleMuted,
    videoMuted,
    handleToggleVideoMuted,
    recordingStatus,
    handleToggleRecording,
    setCallDirection,
    setCallDuration,
    handleCallEnded,
    handleCallAnswered,
  };

  return (
    <SoftphoneContext.Provider value={defaultContext}>
      {(connecting || activeCall) && <ActiveSoftphoneCall />}
      {incomingCall && <IncomingSoftphoneCallAlert />}
      {children}
    </SoftphoneContext.Provider>
  );
};

function useSoftphone() {
  return useContext(SoftphoneContext);
}

export { SoftphoneProvider, useSoftphone };
